import axios from 'axios';
import DOMPurify from 'dompurify';
import sitefinityUrl from '@/utils/dynamicEnvUrls';

const sitefinityAPI = sitefinityUrl + '/api/default/';

export const getPageDataService = async (modpath, filter, expand, orderBy) => {
  try {
    const e = (expand != undefined ) ? '$expand=' + expand +'&' : ''; // This expands things like Related Media within the dynamic module.
    const f = (filter != undefined) ? '$filter=' + encodeURIComponent(filter) + '&' : '';
    const o = (orderBy != undefined) ? '$orderby=' + encodeURIComponent(orderBy) + '&' : '';
    const url = sitefinityAPI + modpath + '?' + f + e + o;

    const response = await axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    });

    if (response.status === 200) {
      // const urlRegex = /https?:\/\/([a-zA-Z0-9-]+\.)*tkmaxx\.com\.au(\/[^\/]+)/gi;
      const urlRegex = /href=["'](https?:\/\/([a-zA-Z0-9-]+\.)*tkmaxx\.com\.au(\/[^\/]+))["']/gi;
      let data = response.data.value;
      // console.log(modpath + ' ' + filter + ' ' + (Array.isArray(data) && data.length !== 1) + ' data :', data);
      if (Array.isArray(data) && data[0]?.Content && data.length !== 1) { // Run through lists replacing URLs and sanitize HTML content
      // if (modpath === 'listitems' || modpath === 'blogpostsdata') {
        data = data.map(item => {
          if (item?.Content) {
            item.Content = item.Content.replace(urlRegex, (match, p1, p2, p3) => {
              return match.replace(p1, p3); // Replace the full URL with just the path
            });
            item.Content = DOMPurify.sanitize(item.Content); // Purify HTML content making it more secure
          }
          return item;
        });
      } else if (data[0]?.Content) {
        data = normalizeData(data); // Normalize data to handle single object in array case
        data.Content = data.Content.replace(urlRegex, (match, p1, p2, p3) => {
          return match.replace(p1, p3); // Replace the full URL with just the path
        });
        data.Content = DOMPurify.sanitize(data.Content) // Purify HTML content making it more secure
      } else {
        data = normalizeData(data); // Normalize data to handle single object in array case
      }
      return data;
    } else {
      throw new Error('Failed to fetch page data');
    }
  } catch (error) {
    console.error('Error fetching page data:', error);
    throw error;
  }
};

// If data is a single object, apply the replacement directly
const normalizeData = (data) => {
  if (Array.isArray(data) && data.length === 1) {
    return data[0];
  }
  return data;
};