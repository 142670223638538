import { ref } from 'vue';

export function useMetaDataService() {
  const baseTitle = 'TK Maxx Australia'; // Base title
  const defaultTitle = '';
  const defaultMetaDescription = 'Big brands. Small prices. Shop fashion, home, beauty, kids and so much more at a store near you.';

  const title = ref(baseTitle + defaultTitle);
  const metaDescription = ref(defaultMetaDescription);

  const METADATA = [
    { key: 'home-page', 
      title: ' - Home', 
      metaDescription: "Big brands. Small prices. Shop fashion, home, beauty, kids and so much more at a store near you." },
    { key: 'get-inspired', 
      title: ' - Get Inspired', 
      metaDescription: "See what’s in store, right now! New stock arrives all the time." },
    { key: 'store-locator', 
      title: ' - Store Locator', 
      metaDescription: "We have over 80 stores! Find your closest TK Maxx locations, hours and contact info." },
    { key: 'new-store-openings', 
      title: ' - New Store Openings', 
      metaDescription: "Find out where we’re opening next! It could be right near you!" },
    { key: 'fan-finds', 
      title: ' - Fan Finds', 
      metaDescription: "TK Maxx lovers share their best finds! Share yours by tagging us on Instagram." },
    { key: 'how-we-do-it', 
      title: ' - How We Do It', 
      metaDescription: "Who are we and how do we offer such great value? Find the answers to these questions and more." },
    { key: 'subscribe', 
      title: ' - Newsletter Sign Up', 
      metaDescription: "Be in the know. Sign up for the TK Maxx newsletter & get first dibs on the latest TK Maxx news." },
    { key: 'careers', 
      title: ' - Careers', 
      metaDescription: "Be part of the world’s leading off-price apparel and homewares retailer! Learn more about our culture and people development." },
    { key: 'media-room', 
      title: ' - Media Room', 
      metaDescription: "Press Releases, Photo Gallery and Recent Videos. Information about TJX Australia, TJX Companies and off-price retail." },
    { key: 'compare-at-pricing', 
      title: ' - Compare At Pricing', 
      metaDescription: "How we price our products at TK Maxx." },
    { key: 'community', 
      title: ' - Community', 
      metaDescription: "We're passionate about being a responsible retailer. Globally, we have an amazing history of giving back and it’s at the heart of how we do business." },
    { key: 'corporate-responsibility', 
      title: ' - Corporate Responsibility', 
      metaDescription: "We're passionate about being a responsible retailer. Globally, we have an amazing history of giving back and it’s at the heart of how we do business." },
    { key: 'faqs', 
      title: ' - Frequently Asked Questions', 
      metaDescription: "Feeling curious? We've put together a list of our most frequently asked questions. Get the answers you need today!" },
    { key: 'contest', 
      title: ' - Email Contest', 
      metaDescription: "Your chance to win a $200 TK Maxx gift card every time you spot a lucky link in our emails." },
    { key: 'contact', 
      title: ' - Contact Us', 
      metaDescription: "Questions? Comments? Compliments or complaints? We want to hear from you today." },
    { key: 'product-recalls', 
      title: ' - Product Recalls', 
      metaDescription: "Here we list our current recall notices for TK Maxx." },
    { key: 'privacy-policy', 
      title: ' - Privacy Policy', 
      metaDescription: "This Privacy Notice sets out the manner in which TK Maxx collects, uses, discloses and otherwise manages the personal information of its customers." },
    { key: 'terms-conditions', 
      title: ' - Terms and Conditions', 
      metaDescription: "Here we outline the terms and conditions for TK Maxx, our Copyright and Trademark ownership and all other legal documents related to TK Maxx." },
    { key: 'gift-cards', 
      title: ' - Gift Cards', 
      metaDescription: "Give the gift of choice with an expiry free TK Maxx Gift Card" },
    { key: 'not-found', 
      title: ' - 404 Page Not Found', 
      metaDescription: "Getting lost is half the fun at TK Maxx. Click here to go back to the home page." }
  ];

  const setPage = (url) => {
    const homeUrl = '/home-page';
    const foundMetaData = url === '/' ? METADATA.find(data => homeUrl.includes(data.key)) : METADATA.find(data => url.toLowerCase().includes(data.key));
    
    if (foundMetaData) {
      title.value = baseTitle + foundMetaData.title;
      metaDescription.value = foundMetaData.metaDescription;
    } else {
      title.value = baseTitle + defaultTitle;
      metaDescription.value = defaultMetaDescription;
    }
  };

  return {
    setPage,
    metaTitle: () => title.value,
    metaDescription: () => metaDescription.value
  };
}
